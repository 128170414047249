import {
  DocumentType,
  ProduitType,
  ReservationType,
} from '@innedit/innedit-type';
import dayjs from 'dayjs';
import { Form } from 'packages/formidable';
import { ReservationData } from 'packages/innedit';
import React, { FC } from 'react';

import Slots from '~/containers/Slots';
import Variants from '~/containers/Variants';
import IconShareSquare from '~/icons/ShareSquare';
import reservation from '~/params/produit/reservation.json';

import Media from '../../../Media';
import Modal, { ViewModalProps } from '../../../View/Modal';

interface ModalProduitReservationProps extends Omit<ViewModalProps, 'title'> {
  espaceId: string;
  produit: DocumentType<ProduitType>;
}
const ModalProduitReservation: FC<ModalProduitReservationProps> = ({
  espaceId,
  closeOnClick,
  produit,
}) => {
  const handleOnClick = async (values?: any): Promise<void> => {
    if (espaceId) {
      const reservationData = new ReservationData({
        espaceId,
      });

      await reservationData.create({
        ...values,
        espaceId,
        expiredAt: values.hasExpirationDate
          ? dayjs(values.expiratedAt).toISOString()
          : undefined,
        priceId: values.price,
        productId: produit.id,
        quantity: 1,
      } as ReservationType);

      if (closeOnClick) {
        closeOnClick();
      }
    }
  };

  return (
    <Modal closeOnClick={closeOnClick} title="Réservation">
      <a
        className="float-right flex space-x-3"
        href={`/${produit.espaceId}/${produit.id}/`}
        rel="noreferrer"
        target="_blank"
      >
        <IconShareSquare /> <span>Voir la page de réservation</span>
      </a>
      <p>Tu es sur le point de réserver le produit suivant :</p>
      <div className="my-6 rounded bg-light-500 p-6 ">
        <div className="flex space-x-6">
          {produit.medias && produit.medias.length > 0 && (
            <Media
              media={produit.medias[0]}
              options={{
                background: '#ffffff',
                crop: 'contain',
                height: 100,
                width: 100,
              }}
            />
          )}
          <div className="flex-1">
            <h2 className="font-bold">{produit.label}</h2>
            {!produit.hasSlots &&
              !produit.hasOptions &&
              produit.hasInventory &&
              produit.qtyAvailable > 0 && (
                <span>
                  Ce produit possède {produit.qtyAvailable} pièces en stock
                </span>
              )}
            {!produit.hasSlots &&
              !produit.hasOptions &&
              !produit.hasInventory && (
                <span>Ce produit ne suit pas l&apos;inventaire</span>
              )}
            {(produit.hasOptions || produit.hasSlots) && (
              <span>Ce produit possède des déclinaisons</span>
            )}
          </div>
          {produit.hasOptions && (
            <div>
              <Variants produit={produit} />
            </div>
          )}
          {produit.hasSlots && (
            <div>
              <Slots produit={produit} />
            </div>
          )}
        </div>
      </div>
      <Form
        datas={reservation}
        footerProps={{
          className: 'mt-6 flex justify-end',
        }}
        name="reservation"
        onSubmit={handleOnClick}
        params={{
          collectionName: 'produits',
          docId: produit.id,
          ...produit,
        }}
        submitProps={{
          label: 'Enregistrer la réservation',
        }}
        title="Réservation"
      />
    </Modal>
  );
};

export default ModalProduitReservation;
