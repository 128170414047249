import { DocumentType, ProduitType } from '@innedit/innedit-type';
import { ProduitData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import SlotsComponent from '../../components/Slots';

const Slots: FC<{
  produit: DocumentType<ProduitType>;
}> = ({ produit }) => {
  const [slots, setSlots] = useState<DocumentType<ProduitType>[]>();

  useEffect(() => {
    const productData = new ProduitData({
      espaceId: produit.espaceId,
      wheres: {
        kind: 'slot',
        parent: produit.id,
      },
    });

    productData.watch(docs => setSlots(docs));
  }, [produit.espaceId, produit.id]);

  if (!slots) {
    return null;
  }

  return <SlotsComponent datas={slots} />;
};

export default Slots;
