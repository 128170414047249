import { DocumentType, ProduitType } from '@innedit/innedit-type';
import { ProduitData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

const Variants: FC<{
  produit: DocumentType<ProduitType>;
}> = ({ produit }) => {
  const [variants, setSlots] = useState<DocumentType<ProduitType>[]>();

  useEffect(() => {
    const productData = new ProduitData({
      espaceId: produit.espaceId,
      wheres: {
        kind: 'variant',
        parent: produit.id,
      },
    });

    productData.watch(docs => setSlots(docs));
  }, [produit.espaceId, produit.id]);

  if (!variants) {
    return null;
  }

  return (
    <div>
      {variants.map(variant => (
        <div key={variant.id} className="flex space-x-3 justify-end">
          <strong>{variant.label}</strong>
          <span>{variant.qtyAvailable}</span>
        </div>
      ))}
    </div>
  );
};

export default Variants;
